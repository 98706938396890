import React, { useState, useEffect } from "react"
import { SectionTitle } from "../components/styled"
import WrapperRight from "../components/WrapperRight"
import JobContent from "../components/JobContent"
import SearchJob from "../components/SearchJob"
import { Seo } from "../components/seo"
import useJobs from "../hooks/useJobs"
const JobsCategoriesTemplate = props => {
  const dataJobs = useJobs("Japanese")
  const dataJobCategorie = props.data.allStrapiJobs.nodes
  const [dataGroupCat, setDataGroupCat] = useState([])
  useEffect(() => {
    if (dataJobs !== undefined) {
      for (let i = 0; i < dataJobs.length; i++) {
        if (dataJobCategorie[0].JobCategorie === dataJobs[i].JobCategorie) {
          setDataGroupCat(prevState => [...prevState, dataJobs[i]])
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <Seo
        title={`job-categorie: ${dataJobCategorie[0].JobCategorie} | メキシコの求人・転職・就職情報【QUICK GLOBAL MEXICO】`}
      />
      <section className="container jobs-listing">
        <div className="row">
          <div className="col-lg-9">
            <div className="jobs-listing__search">
              <SectionTitle>メキシコ求人・就職・転職情報</SectionTitle>
              <hr className="u-line-bottom" />
              <div className="jobs-listing__search--box">
                <SearchJob />
              </div>
            </div>
            <div
              className="jobs-listing__recommended-jobs"
              style={{ padding: "0" }}
            ></div>
            <div className="jobs-listing__job-info">
              <SectionTitle>
                {dataJobCategorie[0].JobCategorie} : {dataGroupCat.length} 件
              </SectionTitle>
              <hr className="u-line-bottom" />
              {dataGroupCat.map((jobs, i) => (
                <>
                  {dataJobCategorie[0].JobCategorie === jobs.JobCategorie ? (
                    <>
                      <JobContent key={i} jobs={jobs} />
                    </>
                  ) : (
                    ""
                  )}
                </>
              ))}
            </div>
          </div>
          <WrapperRight />
        </div>
      </section>
    </>
  )
}

export default JobsCategoriesTemplate
export const query = graphql`
  query jobCategories($slug: String!) {
    allStrapiJobs(filter: { JobCategorie: { eq: $slug } }) {
      nodes {
        JobCategorie
      }
    }
  }
`
